import axios from '@/axios/globalaxios'

// 小组金额排名
export const findOrderTransactionRankingInformation = data =>{
    return axios({
        url: "/tradelab/transaction/findOrderTransactionRankingInformation",
        method: 'post',
        data:data
    })
}
// 查询课程下报告分类 报告饼图
export const findFileGroupByType = data =>{
    return axios({
        url: "/tradelab/tFundFundfiles/findFileGroupByType",
        method: 'post',
        data:data
    })
}

export const getAcidByclassID = data =>{
    return axios({
        method: 'post',
        url: '/tradelab/transaction/getAcidByclassID',
        data: data
    })
}

